$(document).ready(function() {
  $('th').one('click',(function() {
    var groupId = $(this).closest('table').attr("id");
    className = groupId;
    var colIndex = $(this).data('col-index');
    rowsClass = "tbody." + groupId + " tr";
    var rows = $(rowsClass).get();

    rows.sort(function(a, b) {
      tdElements = rowsClass + " td";
      var aVal = $(a).find('td').eq(colIndex - 1).text();
      var bVal = $(b).find('td').eq(colIndex - 1).text();

      var aIsNumeric = !isNaN(aVal) && aVal !== '' && aVal !== null;
      var bIsNumeric = !isNaN(bVal) && bVal !== '' && bVal !== null;
      var aIsDate = isDate(aVal);
      var bIsDate = isDate(bVal);

      if (aIsNumeric && bIsNumeric) {
        aVal = parseFloat(aVal);
        bVal = parseFloat(bVal);
      } else if (aIsDate && bIsDate) {
        aVal = new Date(aVal);
        bVal = new Date(bVal);
      } else if (aIsNumeric) {
        return -1;
      } else if (bIsNumeric) {
        return 1;
      } else if (aIsDate && !bIsDate) {
        return -1;
      } else if (!aIsDate && bIsDate) {
        return 1;
      }

      if (aVal < bVal) {
        return 1;
      } else if (aVal > bVal) {
        return -1;
      } else {
        return 0;
      }
    });

    $.each(rows, function(index, row) {
      tbodyClass = "tbody ." + groupId
      $('tbody').append(row);
    });
  }));

  function isDate(dateString) {
    var regexDate = /^\d{4}-\d{2}-\d{2}$/;
    return regexDate.test(dateString);
  }
});
